import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';
import { useState } from 'react';

export const ProvablyFair = () => {
	const navigate = useNavigate();
	const [selectedImage, setSelectedImage] = useState(null);

	const openModal = (image: any) => {
		setSelectedImage(image);
	};

	const closeModal = () => {
		setSelectedImage(null);
	};

	return (
		<Layout customBG="bg-background-dark" logoWhite>
			<div className="px-3.5 pt-7 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between px-3.5 pt-10 pb-6 lg:flex-col lg:justify-start lg:items-start lg:gap-4">
					<ButtonBack classNameIcon="text-custom-white" />
					<p className="text-xl font-semibold text-center font-bahnschrift text-custom-white lg:text-primary lg:text-left">
						Provably Fair
					</p>
					<div></div>
				</div>

				<div className="text-sm text-left text-custom-gray4 font-bahnschrift lg:rounded-lg lg:bg-custom-gray2 lg:p-8">
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							O que é Probably fair?
						</span>
					</p>
					<br />
					Um sistema ou processo projetado para ser justo,
					transparente e ter um alto grau de confiança de que os
					resultados não foram manipulados.
					<br />
					<br />
					No contexto de <b>jogos online</b>, por exemplo, "probably
					fair" significa que o resultado de uma aposta ou jogo é
					determinado por um algoritmo ou processo que pode ser
					auditado ou verificado de maneira pública, permitindo que os
					jogadores tenham confiança de que o jogo não favorece
					injustamente a casa, um determinando usuário ou o provedor.
					<br />
					<br />
					Portanto, "probably fair" implica em um equilíbrio entre
					confiança e a possibilidade de erro, sempre com o objetivo
					de criar um ambiente mais justo para os participantes.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							Entenda o probably fair.
						</span>
					</p>
					<br />
					Na consulta do torneio:
					<br />
					<br />
					<p className="pl-3">
						1. Ao criar um novo torneio, a “Chave pública gerada
						pelo servidor” é automaticamente disponibilizada e
						exibida imediatamente ao consultar. Essa chave é a
						garantia que sua inscrição não sofrerá alteração.
					</p>
					<br />
					<picture
						onClick={() =>
							openModal(
								'https://pga-images.s3.us-east-1.amazonaws.com/pf/01.jpg'
							)
						}
						className="flex items-center justify-center"
					>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/01.jpg"
							media="(max-width: 767px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/01.jpg"
							media="(max-width: 768px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/01.jpg"
							media="(max-width: 1024px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<img
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
							alt="FUTPro ProvablyFair 01"
							src="https://pga-images.s3.us-east-1.amazonaws.com/pf/01.jpg"
						/>
					</picture>
					<br />
					<br />
					<p className="pl-3">
						2. Neste mesmo momento, a “Chave privada gerada no
						servidor” também é criada, mas só será disponibilizada
						no fim do torneio.
					</p>
					<br />
					<picture
						onClick={() =>
							openModal(
								'https://pga-images.s3.us-east-1.amazonaws.com/pf/02.jpeg'
							)
						}
						className="flex items-center justify-center"
					>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/02.jpeg"
							media="(max-width: 767px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/02.jpeg"
							media="(max-width: 768px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/02.jpeg"
							media="(max-width: 1024px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<img
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
							alt="FUTPro ProvablyFair 01"
							src="https://pga-images.s3.us-east-1.amazonaws.com/pf/02.jpeg"
						/>
					</picture>
					<br />
					<br />
					<p className="pl-3">
						3. Com o torneio já finalizado, basta clicar no ícone de
						download para baixar um arquivo .txt contendo a chave
						pública, a chave privada e as hashs de todas as
						inscrições que participaram do torneio.
					</p>
					<br />
					<picture
						onClick={() =>
							openModal(
								'https://pga-images.s3.us-east-1.amazonaws.com/pf/03.jpeg'
							)
						}
						className="flex items-center justify-center"
					>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/03.jpeg"
							media="(max-width: 767px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/03.jpeg"
							media="(max-width: 768px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/03.jpeg"
							media="(max-width: 1024px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<img
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
							alt="FUTPro ProvablyFair 01"
							src="https://pga-images.s3.us-east-1.amazonaws.com/pf/03.jpeg"
						/>
					</picture>
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							Audite sua inscrição
						</span>
					</p>
					<br />
					<p className="pl-3">
						1. Ao criar uma nova matricula, você pode visualizar:
					</p>
					<br />
					<p className="pl-6">
						● o A chave pública gerada pelo servidor: já explicado
						acima.
						<br />
						● o A hash das respostas da matrícula: que é uma
						composição da “chave pública gerada pelo servidor” e as
						“respostas da mátrículas”.
						<br />
						● o Botão de download de arquivo .txt com os itens
						citados acima.
						<br />
					</p>
					<br />
					<picture
						onClick={() =>
							openModal(
								'https://pga-images.s3.us-east-1.amazonaws.com/pf/04.jpeg'
							)
						}
						className="flex items-center justify-center"
					>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/04.jpeg"
							media="(max-width: 767px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/04.jpeg"
							media="(max-width: 768px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/04.jpeg"
							media="(max-width: 1024px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<img
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
							alt="FUTPro ProvablyFair 01"
							src="https://pga-images.s3.us-east-1.amazonaws.com/pf/04.jpeg"
						/>
					</picture>
					<br />
					<br />
					<p className="pl-3">2. Valide a hash da sua inscrição.</p>
					<br />
					<picture
						onClick={() =>
							openModal(
								'https://pga-images.s3.us-east-1.amazonaws.com/pf/05.jpeg'
							)
						}
						className="flex items-center justify-center"
					>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/05.jpeg"
							media="(max-width: 767px)"
							className="inset-0 lg:h-20 h-20 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/05.jpeg"
							media="(max-width: 768px)"
							className="inset-0 lg:h-20 h-20 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/05.jpeg"
							media="(max-width: 1024px)"
							className="inset-0 lg:h-20 h-20 object-scale-down z-0"
						/>
						<img
							className="inset-0 lg:h-20 h-20 object-scale-down z-0"
							alt="FUTPro ProvablyFair 01"
							src="https://pga-images.s3.us-east-1.amazonaws.com/pf/05.jpeg"
						/>
					</picture>
					<br />
					<br />
					Na imagem acima, conseguimos verificar que a hash da
					matrícula condiz exatamente com a chave gerada no servidor e
					as respostas da matrícula, garantindo assim que não houve
					nenhum tipo de manipulação.
					<br />
					<br />
					OBS: você pode utilizar o site{' '}
					<span
						onClick={() => {
							navigate('/help');
						}}
						className="cursor-pointer font-bold"
					>
						https://emn178.github.io/online-tools/sha256.html
					</span>{' '}
					para validar ou o site de sua preferência, desde que use
					criptografia em sha256.
					<br />
					<br />
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							Verifique se a inscrição está correta no torneio.
						</span>
					</p>
					<br />
					<p className="pl-3">
						1. Com a chave privada gerada no servidor, as respostas
						das matrículas e a chave privada gerada no servidor é
						possível encontrar no arquivo do torneio a sua hash,
						garantindo assim que a matrícula está inclusa na
						apuração do torneio.
					</p>
					<br />
					<picture
						onClick={() =>
							openModal(
								'https://pga-images.s3.us-east-1.amazonaws.com/pf/06.jpeg'
							)
						}
						className="flex items-center justify-center"
					>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/06.jpeg"
							media="(max-width: 767px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/06.jpeg"
							media="(max-width: 768px)"
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
						/>
						<source
							srcSet="https://pga-images.s3.us-east-1.amazonaws.com/pf/06.jpeg"
							media="(max-width: 1024px)"
							className="inset-0 lg:h-80 h-40 object-contain z-0"
						/>
						<img
							className="inset-0 lg:h-80 h-40 object-scale-down z-0"
							alt="FUTPro ProvablyFair 01"
							src="https://pga-images.s3.us-east-1.amazonaws.com/pf/06.jpeg"
						/>
					</picture>
					<br />
					<br />
				</div>
			</div>
			<Footer />

			{selectedImage && (
				<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
					<div className="relative">
						<img
							src={selectedImage}
							alt="Zoomed"
							className="max-w-full max-h-[90vh] rounded-lg"
						/>
						<button
							onClick={closeModal}
							className="absolute top-2 right-2 bg-white text-black p-2 rounded-full shadow"
						>
							✕
						</button>
					</div>
				</div>
			)}
		</Layout>
	);
};
